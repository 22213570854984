import React from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { Border1 } from '@/components/border'
import { useRequest } from 'ahooks'
import { Call, Equipment, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { CallLight } from '@/components/callLight'
import './index.scss'
import { store } from '@/store'
import { split } from 'lodash'
import { Zoom } from 'swiper/js/swiper.esm'
import { DigitalBlock } from '@/pages/sFactoryBigScreen/digitalBlock'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
interface Props { }
/**当前的产线编号 */
/** 拼接参数 获取跳转的url */
let content = <Loading></Loading>
const getJumpTo = (address: string): string => `${address}?lineCode=${searchParams().lineCode}`
const Line2in1 = () => {
    const lineCode1 = searchParams().lineCode1
    const lineCode2 = searchParams().lineCode2
    const allOvens = store.getState().ovenOrRefrigerators
    const cureentOvens = allOvens.filter((item: any) => item.lineCode === lineCode1 || item.lineCode === lineCode2)


        console.log(cureentOvens)
    const { loading, data, error } = useRequest(() => {
        return Promise.all([
            Equipment.getOvensCurrentParameters(lineCode1),
            Equipment.getOvensCurrentParameters(lineCode2)

        ])
    }, { pollingInterval: 10 * 1000 })

    if (!lineCode1 || !lineCode2) {
        return <Error value='错误的产线编号' />
    }
    if (loading) {
        return content
    }
    let cureentOvensWithTemperature = cureentOvens.map((item: any) => {
        let param = [...data[0], ...data[1]].find((paramItem: any) => item.code === paramItem.ovenCode)

        console.log(param)
        return {
            ...item,
            temperature: param.temperatureInC
            }
    })

    let thumbnails1: Array<any> = [
        { title: '生产', jumpTo: getJumpTo('/line/production'), children: <Thumbnail.Production lineCode={lineCode1} />, border: <Border1 />, width: 420, icon: require('../../../assets/images/production.png') },
        { title: '实时产能', jumpTo: getJumpTo('/line/production'), children: <Thumbnail.Capacity lineCode={lineCode1}/>, border: <Border1 />, width: 420, icon: require('../../../assets/images/quality.png') },
        { title: '能源', jumpTo: getJumpTo('/line/equipment'), children: <Thumbnail.Equipment lineCode ={lineCode1} />, border: <Border1 />, width: 420, icon: require('../../../assets/images/equipment.png') },
    ]

    let thumbnails2: Array<any> = [
        { title: '生产', jumpTo: getJumpTo('/line/production'), children: <Thumbnail.Production lineCode={lineCode2}/>, border: <Border1 />, width: 420, icon: require('../../../assets/images/production.png') },
        { title: '实时产能', jumpTo: getJumpTo('/line/production'), children: <Thumbnail.Capacity  lineCode={lineCode2}/>, border: <Border1 />, width: 420, icon: require('../../../assets/images/quality.png') },
        { title: '能源', jumpTo: getJumpTo('/line/equipment'), children: <Thumbnail.Equipment lineCode ={lineCode2} />, border: <Border1 />, width: 420, icon: require('../../../assets/images/equipment.png') },
    ]
    let pageName = "ZS-TEK S2 智能产线"
   content = (
        <Layout name={pageName}>

            <LineLayout>
                <div style={{ width: 1450, zoom: 0.9 }}>
                    <div className='line-child-title'>S2-1</div>
                    <LineLayout margin={20} height={480} justify='start' gap={50}>
                        {thumbnails1
                            .map((item) => <Card title={item.title} jumpTo={item.jumpTo} showMoreBtn={true} border={item.border} width={item.width} children={item.children} icon={item.icon} />)}


                    </LineLayout>
                    <div className='line-child-title'>S2-2</div>
                    <LineLayout margin={20} height={480} justify='start' gap={50}>
                        {thumbnails2
                            .map((item) =>
                                <Card title={item.title} jumpTo={item.jumpTo} showMoreBtn={true} border={item.border} width={item.width} children={item.children} icon={item.icon} />)}

                    </LineLayout>
                </div>
                <Card bgColor='#2c3b56' radius={10} width={580} height={930} marginTop={60}>
                    <div style={{ zoom: 1.3, marginTop: "20px" }}>
                        <Thumbnail.Environment />
                    </div>
                    <div className="oven-temperature-list">
                        {
                            cureentOvensWithTemperature.map((item: any) =>
                                <div className='oven-temperature-item'>
                                    <DigitalFlop title={item.name} size={0.7} suffix="℃" count={item.temperature}  min={0} type='count' />
                                </div>
                            )
                        }


                    </div>
                </Card>
            </LineLayout>
        </Layout>
    )
    return content
}
export { Line2in1 }
