import React from 'react'
import './index.scss'
interface SimpleCardProps {
    title?: string,
    children?: Object | Array<Object>
}

interface SimpleCardProps {
    width: number,
    height?:number
}
export const SimpleCard = (props: SimpleCardProps) => {
    return <div className='simple-card-content' style={{ width: props.width,height:props.height }}>
        <div className='title'>{props.title}</div>
        {props.children}
    </div>
}