import React, { useState } from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { useRequest, useHistoryTravel, useMount } from 'ahooks'
import { Call, Production, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { store } from '@/store'
import { Border1 } from '@/components/border'
import { SimpleCard } from '@/components/simpleCard'
import './sFactory.scss'
import { PieChart } from '@/components/chart/pieChart'
import Icon from '@/components/icon/icon'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'


//产线编号
const lineCode = searchParams().lineCode || null
const XFactoryHome = (props: any) => {
    const history = useHistory()
    const [pieChartLoading, setPieChartLoading] = useState(true)
    const [s1TodayCount, setS1TodayCount] = useState(0)
    const [s2TodayCount, setS2TodayCount] = useState(0)
    const [s3TodayCount, setS3TodayCount] = useState(0)

    const jumpToProductionLineHomePage = (lineCode: string) => {
        history.push({
            pathname: '/line/lineGroup',
            search: 'lineGroupCode=' + lineCode
        })
    }

    useMount(() => {
        setInterval(() => {
            Promise.all([
                Production.getTodayCount("1"),
                Production.getTodayCount("2"),
                Production.getTodayCount("3")
            ]).then(res => {
                setS1TodayCount(res[0])
                setS2TodayCount(res[1])
                setS3TodayCount(res[2])
                setPieChartLoading(false)
            })

        }, 3000)

    })

    const { loading, data, error } = useRequest(() => {
        return Promise.all([
            Call.hasCallButtonSystem(lineCode),
            Quality.getHasVisionInspectionSystem()

        ])
    })
    if (loading) return <Loading />

    let pageName = `ZS-TEK ${store.getState().projectInfo.shortCode} 智能工厂`

    pageName = 'ZS-TEK 东才智能工厂'
    return (
        <Layout name={pageName} >
            <LineLayout direction='column' padding={[0, 20, 0, 20]}>
                <div className='sfactory-workshop-container'>
                    <LineLayout padding={[20, 0, 20, 0]} >
                        <Card
                            jumpTo='/workshop/production'
                            showMoreBtn={true}
                            border={<Border1></Border1>}
                            title='整厂数据'
                            width='450'
                            height='840'
                            icon={require('../../../assets/images/wcl.png')}
                        >
                            <LineLayout direction='column'>
                                <Thumbnail.WorkshopOverallData />
                                {/* <Thumbnail.Production /> */}
                                {

                                    // pieChartLoading ? <Loading></Loading> :
                                    //     <div style={{ marginTop: "50px" }}>
                                    //         <PieChart
                                    //             color={['#008080', '#d13a49', '#FFB100']}
                                    //             type='percent'
                                    //             data={[{ name: "S1", value: s1TodayCount }, { name: "S2", value: s2TodayCount }, { name: "S3", value: s3TodayCount }]}
                                    //             id="count-chart"
                                    //             title='今日产线产量占比'></PieChart>
                                    //     </div>


                                }
                            </LineLayout>
                        </Card>
                        <LineLayout width={1350} height={840} justify='between' >
                            <div className='sfactory-line-container'>
                                <LineLayout width="310" height='60'>
                                    <p><span>X1 </span>产线</p>
                                    <Button onClick={() => { jumpToProductionLineHomePage("1") }} type="primary" style={{ width: 100, height: 50, fontSize: 20 }}>跳转</Button>
                                </LineLayout>
                                <LineLayout direction='column' gap={15}>
                                    <SimpleCard width={325} title='X1-1' children={<Thumbnail.WorkshopProduction lineCode='1' />}></SimpleCard>
                                    <SimpleCard width={325} title='X1-2' children={<Thumbnail.WorkshopProduction lineCode='2' />}></SimpleCard>
                                </LineLayout>
                            </div>
                            <div className='sfactory-line-container'>
                                <LineLayout width="310" height='60'>
                                    <p><span>X2 </span>产线</p>
                                    <Button onClick={() => { jumpToProductionLineHomePage("2") }} type="primary" style={{ width: 100, height: 50, fontSize: 20 }}>跳转</Button>
                                </LineLayout>
                                <LineLayout direction='column' gap={15}>
                                    <SimpleCard width={325} title='X2-1' children={<Thumbnail.WorkshopProduction lineCode='3' />}></SimpleCard>
                                    <SimpleCard width={325} title='X2-2' children={<Thumbnail.WorkshopProduction lineCode='4' />} ></SimpleCard>
                                </LineLayout>

                            </div>
                            <div className='sfactory-line-container'>
                                <LineLayout width="310" height='60'>
                                    <p><span>X3 </span>产线</p>
                                    {/* lineCode 4 为2-2产线 */}
                                    <Button onClick={() => { jumpToProductionLineHomePage("3") }} type="primary" style={{ width: 100, height: 50, fontSize: 20 }}>跳转</Button>
                                </LineLayout>
                                <LineLayout direction='column' gap={15}>
                                    <SimpleCard width={325} title='X3-1' children={<Thumbnail.WorkshopProduction lineCode='5' />}></SimpleCard>
                                    <SimpleCard  width={325}title='X3-2' children={<Thumbnail.WorkshopProduction lineCode='6' />} ></SimpleCard>
                                </LineLayout>
                            </div>
                            <div className='sfactory-line-container'>
                                <LineLayout width="310" height='60'>
                                    <p><span>X4 </span>产线</p>
                                    <Button onClick={() => { jumpToProductionLineHomePage("4") }} type="primary" style={{ width: 100, height: 50, fontSize: 20 }}>跳转</Button>
                                </LineLayout>
                                <LineLayout direction='column' gap={15}>
                                    <SimpleCard width={325} title='X4-1' children={<Thumbnail.WorkshopProduction lineCode='7' />}></SimpleCard>
                                    <SimpleCard width={325} title='X4-2' children={<Thumbnail.WorkshopProduction lineCode='8' />} ></SimpleCard>
                                </LineLayout>
                            </div>
                        </LineLayout>
                    </LineLayout>
                </div>
                <Card border={<Border1></Border1>} height={100}   >
                    <Thumbnail.WorkshopEnvironment></Thumbnail.WorkshopEnvironment>
                </Card>
            </LineLayout>

        </Layout >
    )
}
export { XFactoryHome }
