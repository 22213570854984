/*
 * @Description:产线设备缩略图
 * @Author: huixu
 */
import React from 'react'
import { EasyGridLayout, LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { useRequest } from 'ahooks'
import { EnvironmentApi } from '@/api/request'
import { searchParams } from '@/utils'
import { Error } from '@/components/error'
import Loading from '@/components/loading/loading'
import _ from 'lodash'
const lineCode = searchParams().lineCode
let content = <Loading />

const Environment = () => {
  const { data, loading, error } = useRequest(() => EnvironmentApi.getCurrentEnvironmentValues(lineCode), { pollingInterval: 5 * 1000 })
  if (loading) return content
  if (error) {
    content = <Error />
    return <Error />
  }
  content = <LineLayout  height={370} width={360}  padding={[40,0,0,40]} gap={80} >
    <DigitalFlop title="温度"  size={1.3} suffix="℃" count={_.round(data.temperatureInC)} max={35} min={0} type='count' />
    <DigitalFlop title="湿度" size={1.3} count={_.round(data.humidness * 100)} suffix="%" max={80} min={30} type='count' />
    <DigitalFlop title="噪音" size={1.3} count={_.round(data.noiseIndB)} suffix="dB" max={65} type='count' />
    <DigitalFlop title="PM2.5" size={1.3} count={_.round(data.pM25)} suffix="μg/m³ " max={30} type='count' />
  </LineLayout >

  return content
}
export { Environment }
