import React from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { Border1 } from '@/components/border'
import { useRequest } from 'ahooks'
import { Call, Equipment, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { CallLight } from '@/components/callLight'
import './index.scss'
import { store } from '@/store'
import { split } from 'lodash'
import { Zoom } from 'swiper/js/swiper.esm'
import { DigitalBlock } from '@/pages/sFactoryBigScreen/digitalBlock'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { Production } from './production'
import { Capacity } from './capacity'
import { Environment } from './environment'
import { Energy } from './energy'



interface Props { }
/**当前的产线编号 */
/** 拼接参数 获取跳转的url */
let content = <Loading></Loading>

const LineGroup = () => {
    const lineGroupCode =  searchParams().lineGroupCode 
    const { loading, data, error } = useRequest(() => {
        return Promise.all([
           

        ])
    }, { pollingInterval: 10 * 1000 })

    if (!lineGroupCode) {
        return <Error value='错误的产线编号' />
    }

    if (loading) {
        return content
    }
   
    let  pageName =`ZS-TEK 东才智能工厂X${lineGroupCode} 产线`
   content = (
        <Layout name={pageName}>
             <LineLayout height={600}  margin={[30,0,30,0]}>
                <Card title='产量' width={910} border={<Border1/>} children={<Production/>} icon={require('../../../assets/images/production.png')}/> 
                <Card title='实时产能' width={910} border={<Border1/>} children={<Capacity/>} icon={require('../../../assets/images/quality.png')}></Card>    
            </LineLayout>  
             <LineLayout height={300} >
                 <Card title='能耗' width={910} border={<Border1/>} children={<Energy/>} icon={require('../../../assets/images/equipment.png')}></Card> 
                 <Card title='环境' width={910} border={<Border1/>} children={<Environment/>} icon={require('../../../assets/images/th.png')}></Card> 
            </LineLayout>   
        </Layout>
    )
    return content
}
export { LineGroup }
