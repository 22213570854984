//当前开发环境对应的项目 d2温州 d1成都 e1男鞋 jdd3金帝 s1温州智能工厂 x东才工厂
const currentProjectDev = 's1'

const hostname = window.location.hostname
const projectName = hostname.split('.')[0]
let requestBaseUrl = ''
let productionRequestUrl = ''
if (hostname.includes('zs-tek')) {
  productionRequestUrl = `https://api.${projectName}.zs-tek.com`
} else {
  productionRequestUrl = `http://${hostname}:11003`
}
switch (process.env.NODE_ENV) {
  case 'development':
    // requestBaseUrl ="https://api.s1.zs-tek.com" 
    //本地测试 http://192.168.2.21:11103
      requestBaseUrl ="http://192.168.1.151:11103"
     requestBaseUrl = `https://api.s1.zs-tek.com`
    break;
  case 'production':
    requestBaseUrl = productionRequestUrl
    if(projectName==='s1' || projectName==='s') {
      requestBaseUrl = `https://api.s1.zs-tek.com`
    }
    break;
  default:
    requestBaseUrl = `https://api.${currentProjectDev}.zs-tek.com`
}

// 本地调试
// requestBaseUrl = 'http://192.168.1.151:11103'

export { requestBaseUrl }
