/*
 * @Description:数字翻牌器
 * @Author: huixu
 */
import React, { Fragment, useState } from 'react'
import { useUpdateEffect } from 'ahooks'
import CountUp from 'react-countup'
import './digitalFlop.scss'
import { PromisePolyfill } from '@babylonjs/core'
interface DigitalFlopProps {
  count?: number
  title?: string
  suffix?: string
  size?: number
  minWidth?: number
  decimals?: number
  color?: string
  max?: number
  min?: number,
  type?: string
}

/**
 * 可以指定字颜色 默认会#05d380
 * 无法调节字体大小 但是可以通过 size 调节放大倍率
 * Count>MAx || Count < MIN 会显示红色
 * 
 */

/**
 * 获取小数位数
 * @param count 
 * @returns 
 */
const getDecimalsByCount = (count: number, decimals: number) => {
  if (!!decimals) {
    return decimals
  }
  if (!count) {
    return 0
  }
  return count.toString().includes('.') ? count.toString().split(".")[1].length : 0
}

/**
 * 格式化小时 转换成xx小时xx分钟的形式 超过100个小时则忽略分钟
 * @param hours 
 */
const formatHours = (hours: number) => {

  if (hours <= 1) {
    return [
      { count: Math.floor(hours * 60), suffix: '分钟' },
    ]
  }

  if (hours > 100) {
    return [
      { count: Math.floor(hours), suffix: '小时' },
    ]
  }
  return [
    { count: Math.floor(hours), suffix: '小时' },
    { count: (hours - Math.floor(hours)) * 60, suffix: '分', color: 'orange' }
  ]
}

const DigitalFlop = (props: DigitalFlopProps) => {
  const [start, setStart] = useState(0)
  useUpdateEffect(() => {
    setStart(() => props.count)
  }, [props.count])

  let digitalContentColor = props.color || '#05d380'

  if (props.max && props.max < props.count) {
    digitalContentColor = 'red'
  }

  if (props.min && props.min > props.count) {
    digitalContentColor = 'red'
  }

  return (
    <div className="digital-flop" style={{ zoom: props.size, minWidth: props.minWidth + 'px' }}>
      <div className="digital-title">{props.title}</div>
      <div className="digital-content" style={{ color: digitalContentColor }}>
        {
          props.type === 'count' ?
            <Fragment>
              {
                props.count === null ? '--' :
                  <CountUp
                    decimals={getDecimalsByCount(props.count, props.decimals)}
                    start={start}
                    end={props.count}
                    decimal="."
                    separator="," />

              }
              <div className="digital-suffix">{props.suffix}</div>
            </Fragment>
            : null
        }
        {/* 如果类型是时间则单独处理 TODO */}
        {
          props.type === 'time' ?
            <Fragment>
              {
                formatHours(props.count).map(item => {
                  return <Fragment>
                    <CountUp start={start} end={item.count} decimal="." separator="," />
                    <div className="digital-suffix" style={{ marginRight: '10px' }}>{item.suffix}</div>
                  </Fragment>
                })
              }
            </Fragment> : null
        }
      </div>
    </div>
  )
}
export { DigitalFlop }
