import React from 'react'
import { WorkshopDefaultHome } from './default'
import { SFactoryHome } from './sFactory'
import { XFactoryHome } from './xFactory'

import { store } from '@/store'
//这个组件对应 “/workshop” 路由
//组件本身不提供内容 只作为一个容器
//根据不同项目可以新增或导入不同的内容
//如果无特殊情况则默认 default 页面
const Home = (props: any) => {
  let shortCode = store.getState().projectInfo.shortCode
  console.log(shortCode)
  if(shortCode === "S1" || shortCode ==="S") {
    return <SFactoryHome></SFactoryHome>
  }

  if(shortCode === "X" ) {
    return <XFactoryHome></XFactoryHome>
  }
  return <WorkshopDefaultHome></WorkshopDefaultHome>
}

export { Home }