import React, { useEffect } from 'react';
import { store } from '@/store'
import { IOTD } from './iotD';
import { IOTY } from './iotY';
import { IOTJDD } from './iotJDD';
import { IOTE } from './iotE';

function IOTUpgradeSecond () {

  useEffect(() => {
    // console.log(store.getState().projectInfo.shortCode);
  }, [])

  const ComponentJudgment = (projectName: string) => {
    console.log(1111, projectName);
    let component = <></>;
    switch (projectName) {
      case 'Y1':
        component = <IOTY />;
        break;
      case 'JDD3':
        component = <IOTJDD />;
        break;
      case 'E1':
        component = <IOTE />;
        break;
      default:
        component = <IOTD />;
    }
    return component;
  }

  return (
    <React.Fragment>
      {ComponentJudgment(store.getState().projectInfo.shortCode)}
    </React.Fragment>
  );
}

export { IOTUpgradeSecond }