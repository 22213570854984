/*
 * @Description:车间看板西部的环境参数
 */
import React from 'react'
import { EasyGridLayout, LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { useRequest } from 'ahooks'
import { EnvironmentApi } from '@/api/request'
import { searchParams } from '@/utils'
import { Error } from '@/components/error'
import Loading from '@/components/loading/loading'
import './index.scss'
import _ from 'lodash'
import Icon from '@/components/icon/icon'

let content = <Loading />

const WorkshopEnvironment = () => {
    const { data, loading, error } = useRequest(() => EnvironmentApi.getCurrentEnvironmentValues(), { pollingInterval: 5 * 60 * 1000 })
    if (loading) return content
    if (error) {
        content = <Error />
        return <Error />
    }
    

    content = <LineLayout justify='start'>
        <div className='env-m-title'>
            <LineLayout padding={[25, 0, 0, 0]} justify='start'>
                <Icon size={3} url={require('../../../assets/images/env.png')} ></Icon>
                <span>车间环境  </span>
            </LineLayout>
        </div>
        <LineLayout padding={[30, 0, 0, 0]} justify='start' width={350}>
            <Icon size={2.5} url={require('../../../assets/images/temperature.png')} ></Icon>
            <span className='env-title'>温度</span>
            <span className='env-value'>{_.round(data.temperatureInC)}</span>
            <span className='env-unit'>°C</span>
    
        </LineLayout>
        <LineLayout padding={[30, 0, 0, 0]} justify='start'  width={350}>
            <Icon size={2.5} url={require('../../../assets/images/humidity.png')} ></Icon>
            <span className='env-title'>湿度</span>
            <span className='env-value'>{_.round(data.humidness * 100)} </span>
            <span className='env-unit'>%</span>
        </LineLayout>
        <LineLayout padding={[30, 0, 0, 0]} justify='start'  width={350} >
            <Icon size={2.5} url={require('../../../assets/images/noise.png')} ></Icon>
            <span className='env-title'>噪音</span>
            <span className='env-value'>{_.round(data.noiseIndB)}</span>
            <span className='env-unit'>dB</span>
        </LineLayout>
        <LineLayout  padding={[30, 0, 0, 0]} justify='start'  width={350}  >
            <Icon size={2.5 } url={require('../../../assets/images/pm25.png')} ></Icon>
            <span className='env-title'>PM2.5</span>
            <span className='env-value'>{_.round(data.pM25)}</span>
            <span className='env-unit'>μg/m³</span>
        </LineLayout>
    </LineLayout>
    return content
}
export { WorkshopEnvironment }
