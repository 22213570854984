import React from 'react'
import { LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { PieChart } from '@/components/chart/pieChart'
import * as API from '@/api/request'
import _ from 'lodash'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { timeRanges } from '@/utils/time'

interface Props {
  data?: Array<any>,
  lineCode?: string
}


const handleChartData = (data: any) => {
  return [
    { name: '稼动时长', value: _.round(data.usedHours, 2) },
    { name: '待机时长', value: _.round(data.idleHours, 2) },
  ]
}


const Energy = (props: Props) => {
let content = React.useRef(<Loading></Loading>)



  const lineCode = props.lineCode ? props.lineCode : searchParams().lineCode
  // 设备
  const { data, error, loading } = useRequest(
    () => {
      return Promise.all([
        API.Equipment.getTodayUtilizationRate(lineCode),
        API.Equipment.getTodayElectricityValues(lineCode, { ...timeRanges.today() }),
        API.Equipment.getUtilizationHours(lineCode, { ...timeRanges.today() }),
      ])
    },
    { pollingInterval: 3 * 1000 }
  )

  if (loading) return content.current
  if (error) {
    content.current = <Error />
    return content.current
  }

  content.current = <React.Fragment>
    <LineLayout justify="start" padding={[20, 20, 0, 30]} >
      
      <DigitalFlop minWidth={250} count={_.round(data[1].quantityInKWH, 1)} suffix="度" title="今日能耗" color="#efad1f" type='count' size={1.7} />
      <DigitalFlop  count={_.round(data[1].energyUtilizationInPairPerKWH, 1)} suffix="双/度" title="能源利用率" color="#efad1f" type='count' size={1.7} />
    
    </LineLayout>

  </React.Fragment>

  return content.current
}
export { Energy }
