import React, { useRef, useState } from 'react'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineLayout } from '@/layout'
import { LineChart } from '@/components/chart/lineChart'
import * as API from '@/api/request'
import moment from 'moment'
import _ from 'lodash'
import { useMount, useRequest } from 'ahooks'
import Loading from '@/components/loading/loading'
import './index.scss'
// data 0累计产量 1今日产量  2 15天产量
interface Props {
  data?: Array<any>
  jumpTo?: string,
  lineCode?: string
}

const WorkshopProduction = (props: Props) => {
  const count = (count: number) => {
    if (count >= 10000) {
      return _.round(count / 10000, 1)
    }
    return count
  }
  const suffix = (count: number) => {
    if (count >= 10000) {
      return '万双'
    }
    return '双'
  }

  const handleChartData = (data: Array<any>) => {
    let result = { xAxis: [], yAxis: [] }
    if (!Array.isArray(data)) return result
    if (data.length === 0) return result
    result.yAxis = data.map((item: any) => item.count)
    result.xAxis = data.map((item: any) => moment(item.timeRange.beginTime).format('M/D'))
    return result
  }
  const [loading, setLoading] = useState(true)
  const [todayCount, setTodayCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [chartData, setChartData] = useState([])
  const getComponetData = () => {
    Promise.all([
      API.Production.getTotalCount(props.lineCode),
      API.Production.getTodayCount(props.lineCode),
      API.Production.getCountByLast15Days(props.lineCode)
    ]).then(res => {
      setTotalCount(res[0])
      setTodayCount(res[1])
      setChartData(res[2])
      setLoading(false)
    })
  }
  useMount(() => {
    getComponetData()
    setInterval(() => {
      getComponetData()
    }, 3000)
  })

  let content = <React.Fragment >
    {loading ? <Loading></Loading> : <>
      <LineLayout justify="start" padding={[20, 0, 0, 30]} >
        <DigitalFlop
          type='count'
          title="累计产量"
          count={count(totalCount)}
          suffix={suffix(totalCount)}
          minWidth={200}
          size={0.75}
        />
        <DigitalFlop
          size={0.75}
          title="今日产量
         " decimals={0}
          count={todayCount}
          suffix={'双'}
          type='count' />
      </LineLayout>
      <LineChart margin="30px 0 0 0" id={"workshop-line-production-thumbnail-" + props.lineCode} height={170} title="最近15天产量（双）" titleSize={18} data={handleChartData(chartData)} axisStyle={{ fontSize: 15 }} />
    </>
    }
  </React.Fragment>
  return content
}
export { WorkshopProduction }


