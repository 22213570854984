import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import './index.scss'
import Icon from '@/components/icon/icon'
import { CustomCard } from "@/components/card/customCard"
import { LineChart } from "./linechart"
import { BorderBox8, BorderBox12, Decoration8 } from '@jiaminghi/data-view-react'
import moment from "moment"
import { EnvironmentApi, Production, Config } from '@/api/request'
import { useRequest } from 'ahooks';
import _ from 'lodash'
import { timeRanges } from '@/utils/time'

const BigdataX1 = () => {
  // X1-1:1, X1-2:2, X2-1:3, X2-2:4, X3-1:5, X3-2:6, X4-1:7, X4-2:8
  const lineCode = ['1', '2', '3', '4', '5', '6', '7', '8']
  // 状态。-4：未启用此产线 0:错误，1：停止，2：运行，3：报警
  const lineStateIde = {
    '-4': 0,
    '0': 0,
    '1': 1,
    '2': 0,
    '3': 2
  }
  const lineStateColor = ['#60CCB2', '#FBEF50', '#EA5238']
  const [localTime, setLocalTime] = useState(moment().format('YYYY-MM-DD HH:mm'))
  const [lineState, setLineState] = useState([
    {
      name: 'X1-1',
      state: 0,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X1-2',
      state: 1,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X2-1',
      state: 2,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X2-2',
      state: 1,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X3-1',
      state: 0,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X3-2',
      state: 1,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X4-1',
      state: 2,
      runningTime: 0,
      failureTime: 0
    },
    {
      name: 'X4-2',
      state: 0,
      runningTime: 0,
      failureTime: 0
    }
  ])
  const [env, setEnv] = useState([
    {
      name: '温度(℃)',
      icon: require('../../../assets/images/temperature.png'),
      data: 30,
      standard: '22-31',
      warn: false
    },
    {
      name: '湿度(%)',
      icon: require('../../../assets/images/humidity.png'),
      data: 26,
      standard: '70-75',
      warn: false
    },
    {
      name: '噪音(dB)',
      icon: require('../../../assets/images/noises.png'),
      data: 80,
      standard: '≤85',
      warn: true
    },
    {
      name: 'PM2.5(μg/m³)',
      icon: require('../../../assets/images/pm2.5.png'),
      data: 61,
      standard: '≤59',
      warn: false
    }
  ])
  const [linePro, setLinePro] = useState([
    // X1
    {
      lineNames: ['X1-1', 'X1-2'],
      data: [[9955, 9956], [2.8, 2.9]]
    },
    // X2
    {
      lineNames: ['X2-1', 'X2-2'],
      data: [[9955, 9956], [2.8, 2.9]]
    },
    // X3
    {
      lineNames: ['X3-1', 'X3-2'],
      data: [[9955, 9956], [2.8, 2.9]]
    },
    // X4
    {
      lineNames: ['X4-1', 'X4-2'],
      data: [[9955, 9956], [2.8, 2.9]]
    }
  ])
  const [factory, setFactory] = useState({
    production: [13000, 9955],
    capacity: [1695, 4561],
    productionCharts: {
      x: [],
      y: []
    },
    capacityCharts: {
      x: [],
      y: []
    }
  })

  useEffect(() => {
    const timer1 = setInterval(() => {
      setLocalTime(moment().format('YYYY-MM-DD HH:mm'))
    }, 1000)
    // 清除Interval componentWillUnmount
    return () => { clearInterval(timer1) }
  }, [])

  const history = useHistory()
  const toPath = (val: string) => {
    history.push(val)
  }

  // 请求接口
  const { data, loading, error } = useRequest(() => {
    const tem = []
    // 环境 0
    tem.push(EnvironmentApi.getCurrentEnvironmentValues())
    // 线组今日产量 1-8
    for (const ele of lineCode) {
      tem.push(Production.getTodayCount(ele))
    }
    // 线组累计产量 9-16
    for (const ele of lineCode) {
      tem.push(Production.getTotalCount(ele))
    }
    // 产线状况 17
    tem.push(Config.getProductionLines())
    // 工厂产量 18, 19
    tem.push(Production.getTodayCount(), Production.getTotalCount())
    // 工厂产能 20, 21
    tem.push(
      Production.getCapacityInPairPerHourByTimeRange(null, { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'days').format('YYYY-MM-DD') }, 1),
      Production.getMaxCapacityPerHourInDay(null, { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'days').format('YYYY-MM-DD') })
    )
    // 工厂30日产量 22
    tem.push(Production.getCountByLast30Days(null))
    // 工厂30日实时产能 23
    tem.push(Production.getCapacityInPairPerHourByTimeRange(null, timeRanges.lastDays30(), 2))
    return Promise.all(tem)
  }, {
    pollingInterval: 1000 * 20,
    onSuccess: (res, params) => {
      // console.log(res)
      if (res) {
        // 环境
        setEnv([
          {
            name: '温度(℃)',
            icon: require('../../../assets/images/temperature.png'),
            data: res[0]?.temperatureInC || 0,
            standard: '22-31',
            warn: res[0]?.temperatureInC < 22 || res[0]?.temperatureInC > 31
          },
          {
            name: '湿度(%)',
            icon: require('../../../assets/images/humidity.png'),
            data: res[0]?.humidness || 0,
            standard: '70-75',
            warn: res[0]?.humidness < 70 || res[0]?.humidness > 75
          },
          {
            name: '噪音(dB)',
            icon: require('../../../assets/images/noises.png'),
            data: res[0]?.noiseIndB || 0,
            standard: '≤85',
            warn: res[0]?.noiseIndB > 85
          },
          {
            name: 'PM2.5(μg/m³)',
            icon: require('../../../assets/images/pm2.5.png'),
            data: res[0]?.pM25 || 0,
            standard: '≤59',
            warn: res[0]?.pM25 > 59
          }
        ])
        // 线组产量
        setLinePro([
          // X1
          {
            lineNames: ['X1-1', 'X1-2'],
            data: [[res[1], res[2]], [_.floor(res[9] / 10000, 2), _.floor(res[10] / 10000, 2)]]
          },
          // X2
          {
            lineNames: ['X2-1', 'X2-2'],
            data: [[res[3], res[4]], [_.floor(res[11] / 10000, 2), _.floor(res[12] / 10000, 2)]]
          },
          // X3
          {
            lineNames: ['X3-1', 'X3-2'],
            data: [[res[5], res[6]], [_.floor(res[13] / 10000, 2), _.floor(res[14] / 10000, 2)]]
          },
          // X4
          {
            lineNames: ['X4-1', 'X4-2'],
            data: [[res[7], res[8]], [_.floor(res[15] / 10000, 2), _.floor(res[16] / 10000, 2)]]
          }
        ])
        // 产线状况
        setLineState([
          {
            name: 'X1-1',
            state: lineStateIde[res[17][0].state],
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X1-2',
            state: lineStateIde[res[17][1].state],
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X2-1',
            state: lineStateIde[res[17][2].state],
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X2-2',
            state: lineStateIde[res[17][3].state],
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X3-1',
            state: 2,
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X3-2',
            state: 2,
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X4-1',
            state: 2,
            runningTime: 0,
            failureTime: 0
          },
          {
            name: 'X4-2',
            state: 2,
            runningTime: 0,
            failureTime: 0
          }
        ])
        // 工厂数据
        setFactory({
          production: [res[18], res[19]],
          capacity: [res[20][0].count, res[21][0].maxCapacityPerHour],
          productionCharts: {
            x: res[22].map(item => { return moment(item.timeRange.beginTime).format('MM/DD') }),
            y: res[22].map(item => { return item.count }),
          },
          capacityCharts: {
            x: res[23].map(item => { return moment(item.timeRange.beginTime).format('MM/DD') }),
            y: res[23].map(item => { return item.count }),
          }
        })
      }
    }
  })

  return (
    <div>
      <div className="bigdataX1-frame">
        <div className="bigdataX1-header">
          <CustomCard width={1920} height={55} bgImageUrl={require('@/assets/images/border5.png')}>
            <div className="bX1-h-container">
              <div className="bX1-title">东才智能工厂工业大数据</div>
              <div className="bX1-time">
                <Icon url={require('../../../assets/images/clock.png')} size={1.1} />
                <span>{localTime}</span>
              </div>
              <div className="bX1-logo">ZS-TEK</div>
              <div className="bX1-actionBar" onClick={() => toPath('/workshop')}>
                <Icon url={require('../../../assets/images/bd-home.png')} size={1.5} />
                <span>首页</span>
              </div>
            </div>
          </CustomCard>
        </div>

        <div className="bigdataX1-content">
          <div className="bX1-c-left">
            <div className="bX1-c-title">产线状况</div>
            <CustomCard height={500} bgImageUrl={require('@/assets/images/border2.png')}>
              <>
                <div className="bX1-cl-tr">
                  <p style={{ width: '20%' }}>线组</p>
                  <p style={{ width: '16%' }}>状态</p>
                  <p style={{ width: '32%' }}>运行时长(h)</p>
                  <p style={{ width: '32%' }}>故障时长(h)</p>
                </div>
                <ul className="bX1-cl-ul">
                  {
                    lineState.map((item, index) => {
                      return (
                        <li key={index}>
                          <div style={{ width: '20%' }}>{item.name}</div>
                          <div style={{ width: '16%' }}>
                            <div className={`bX1-circle ${item.state === 2 ? 'bX1-cl-breath-light' : ''}`} style={{ backgroundColor: lineStateColor[item.state] }}></div>
                          </div>
                          <div style={{ width: '32%' }}>
                            <CustomCard width={70} height={45} bgImageUrl={require('@/assets/images/border3.png')}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>{item.runningTime}</div></CustomCard>
                          </div>
                          <div style={{ width: '32%' }}>
                            <CustomCard width={70} height={45} bgImageUrl={require('@/assets/images/border3.png')}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>{item.failureTime}</div></CustomCard>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="bX1-cl-ft">
                  <ul>
                    <li><div className="bX1-circle" style={{ backgroundColor: lineStateColor[0] }} /><span>正常</span></li>
                    <li><div className="bX1-circle" style={{ backgroundColor: lineStateColor[1] }} /><span>报警</span></li>
                    <li><div className="bX1-circle" style={{ backgroundColor: lineStateColor[2] }} /><span>停线</span></li>
                  </ul>
                </div>
              </>
            </CustomCard>
          </div>

          <div className="bX1-c-middle">
            <BorderBox8 dur={60}>
              <CustomCard height={528} borderStyle="0px solid yellow">
                <div className="bX1-cm-content">

                  <div className="bX1-cmc-card">
                    <div className="bX1-cmc-card-tblock">
                      <p>今日产量</p>
                      <div className="bX1-cmcct-data-container">
                        <div className="bX1-cmcct-data">{Number(factory.production[0]).toLocaleString()}</div>
                        <span>双</span>
                      </div>
                    </div>
                    <div className="bX1-cmc-card-tblock">
                      <p>累积产量</p>
                      <div className="bX1-cmcct-data-container">
                        <div className="bX1-cmcct-data">{Number(factory.production[1]).toLocaleString()}</div>
                        <span>双</span>
                      </div>
                    </div>
                    <div className="bX1-cmc-card-chart">
                      <LineChart id={'bX1-chart1' + Math.random().toString()} title="近30日生产曲线(双)" dataX={JSON.parse(JSON.stringify(factory.productionCharts.x))} dataY={JSON.parse(JSON.stringify(factory.productionCharts.y))} />
                    </div>
                  </div>

                  <div className="bX1-cmc-card">
                    <div className="bX1-cmc-card-tblock">
                      <p>实时产能</p>
                      <div className="bX1-cmcct-data-container">
                        <div className="bX1-cmcct-data">{Number(factory.capacity[0]).toLocaleString()}</div>
                        <span>双/小时</span>
                      </div>
                    </div>
                    <div className="bX1-cmc-card-tblock">
                      <p>今日最大产能</p>
                      <div className="bX1-cmcct-data-container">
                        <div className="bX1-cmcct-data">{Number(factory.capacity[1]).toLocaleString()}</div>
                        <span>双/小时</span>
                      </div>
                    </div>
                    <div className="bX1-cmc-card-chart">
                      <LineChart id={'bX1-chart2' + Math.random().toString()} title="近30日实时产能曲线(双)" dataX={JSON.parse(JSON.stringify(factory.capacityCharts.x))} dataY={JSON.parse(JSON.stringify(factory.capacityCharts.y))} />
                    </div>
                  </div>

                </div>
                <div className="bX1-cm-image">
                  <img src={require('@/assets/images/x1Line.png')} alt="" />
                </div>
              </CustomCard>
            </BorderBox8>
          </div>

          <div className="bX1-c-right">
            <div className="bX1-c-title">环境</div>
            <CustomCard height={500} bgImageUrl={require('@/assets/images/border2.png')}>
              <div className="bx-cr-card">
                <ul className="bX1-cr-card-content">
                  {
                    env.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="bX1-crcc-container">
                            <p className="bX1-crcc-name">{item.name}</p>
                            <div className="bX1-crcc-icon">
                              <Icon url={item.icon} size={3} />
                              <p className="bX1-crcc-icon-data" style={{ color: item.warn ? '#BE452F' : '#6EEB87' }}>{item.data}</p>
                            </div>
                            <div className="bx-crcc-ft"><span>标准值{item.standard}</span></div>
                          </div>
                          <div style={{ padding: '0 10px' }}>
                            <CustomCard height={6} bgImageUrl={require('@/assets/images/border4.png')} padding="0" />
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </CustomCard>
          </div>
        </div>

        <div className="bigdataX1-footer">
          <div className="bX1-f-trims1">
            <Decoration8 style={{ width: '120px', height: '50px' }} color={['#0383F0', '#0383F0']} />
          </div>
          <div className="bX1-f-trims2">
            <Decoration8 reverse={true} style={{ width: '120px', height: '50px' }} color={['#0383F0', '#0383F0']} />
          </div>
          <div className="bX1-f-content">
            <div className="bX1-fc-titles">
              <p><span>产线/线组</span></p>
              <p><span>今日(双)</span></p>
              <p><span>累计(万双)</span></p>
            </div>
            <ul className="bX1-fc-cards">
              {
                linePro.map((item, index) => {
                  return (
                    <li key={index} className="bX1-fc-card">
                      <div className="bX1-fcc-title">
                        <p>{item.lineNames[0]}</p>
                        <p>{item.lineNames[1]}</p>
                      </div>
                      <div className="bX1-fcc-dataList">
                        <BorderBox12 color={['#045196', 'rgba(0,236,234,0.6)']}>
                          <div className="bX1-fccd-container">
                            <ul>
                              {
                                item.data[0].map((item2, index) => {
                                  return (
                                    <li key={'2' + index}>
                                      <CustomCard width={85} height={50} bgImageUrl={require('@/assets/images/border3.png')}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>{Number(item2).toLocaleString()}</div></CustomCard>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                            <ul>
                              {
                                item.data[1].map((item2, index) => {
                                  return (
                                    <li key={'2' + index}>
                                      <CustomCard width={85} height={50} bgImageUrl={require('@/assets/images/border3.png')}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>{Number(item2).toLocaleString()}</div></CustomCard>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </BorderBox12>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
      {/* 空白 */}
      <div className="bigdataX1-blank"></div>
    </div>
  )
}

export { BigdataX1 }