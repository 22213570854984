import React, { useState } from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { useRequest, useHistoryTravel, useMount } from 'ahooks'
import { Call, Production, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { store } from '@/store'
import { Border1 } from '@/components/border'
import { SimpleCard } from '@/components/simpleCard'
import { DigitalBlock } from './digitalBlock'
import './index.scss'
import { useHistory } from 'react-router-dom'
//产线编号
const SFactoryBigScreenLineProduction = (props: any) => {
    const lineCode = searchParams().lineCode || null
    const history = useHistory()
    const cardBgColor = "#2c3b56";
    const [pieChartLoading, setPieChartLoading] = useState(true)
    const [s1TodayCount, setS1TodayCount] = useState(0)
    const [s2TodayCount, setS2TodayCount] = useState(0)
    const [s3TodayCount, setS3TodayCount] = useState(0)
    const jumpToProductionLineHomePage = (lineCode: string) => {
        history.push({
            pathname: '/line',
            search: '?lineCode=' + lineCode
        })
    }

    useMount(() => {
        setInterval(() => {
            Promise.all([
                Production.getTodayCount("1"),
                Production.getTodayCount("2"),
                Production.getTodayCount("3")
            ]).then(res => {
                setS1TodayCount(res[0])
                setS2TodayCount(res[1])
                setS3TodayCount(res[2])
                setPieChartLoading(false)
            })

        }, 3000)

    })

    const { loading, data, error } = useRequest(() => {
        return Promise.all([
            Call.hasCallButtonSystem(lineCode),
            Quality.getHasVisionInspectionSystem()

        ])
    })
    if (loading) return <Loading />

    let pageName = `ZS-TEK ${store.getState().projectInfo.shortCode} 智能工厂`
    if (store.getState().projectInfo.shortCode === 'S1') {
        pageName = `ZS-TEK S 智能工厂`
    }
    return (
        <Layout name={pageName} >
            <Card width={1860} height={100} bgColor={cardBgColor} radius={5} marginTop={10}>
                <LineLayout justify="between" padding={10}>
                    <DigitalBlock></DigitalBlock>
                    <DigitalBlock></DigitalBlock>
                    <DigitalBlock></DigitalBlock>
                    <DigitalBlock></DigitalBlock>
                    <DigitalBlock></DigitalBlock>
                </LineLayout>
            </Card>
        </Layout >
    )
}
export { SFactoryBigScreenLineProduction }
