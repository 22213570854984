import service from '../service'
export default {
  getProjectInfo: (): Promise<any> => {
    return service({
      url: '/Project/GetProjectInfo'
    })
  },

  /**
   * 获取所有机器人配置
   * @param lineCode 
   * @returns 
   */
  getRobots: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Robot/GetRobots',
      params: { lineCode }
    })
  },


  getOvenOrRefrigerators: (lineCode?: string): Promise<any> => {
    return service({
      url: '/OvenOrRefrigerator/GetOvenOrRefrigerators',
      params: { lineCode }
    })
  },

  getServerCompiledVersion: (): Promise<any> => {
    return service({
      url: '/Project/GetServerCompiledVersion'
    })
  },

  getCameras: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Camera/GetCameras',
      params: { lineCode }
    })
  },

  getGlueBuckets: (lineCode?: string): Promise<any> => {
    return service({
      url: '/GlueBucket/GetGlueBuckets',
      params: { lineCode }
    })
  },

  getHasStopperWorkStations: (lineCode?: string): Promise<any> => {
    return service({
      url: '/HasStopperWorkStation/GetHasStopperWorkStations',
      params: { lineCode }
    })
  },

  getProductionLines: (): Promise<any> => {
    return service({
      url: '/ProductionLine/GetProductionLines'
    })
  },
}
