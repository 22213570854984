import React, { useState } from 'react'
import { Layout, LineLayout } from '../../../layout'
import { Dashboard } from '../../../components/chart/dashboard'
import { useHistoryTravel, useRequest } from 'ahooks'
import { Equipment, Production, Call } from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import _ from 'lodash'
import './index.scss'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { useHistory } from 'react-router-dom'
import { PieChart } from '@/components/chart/pieChart'
let content = <Loading />
export const LineEntry = () => {
    let { loading, error, data } = useRequest(() => {
        return Promise.all(
            [
                Production.getTotalCount('1'),
                Production.getTodayCount('1'),
                Production.getTotalCount('2'),
                Production.getTodayCount('2'),
                Production.getTotalCount('3'),
                Production.getTodayCount('3'),
                Call.hasCallButtonSystem(),

            ])

    }, { pollingInterval: 10 * 1000 })
    const history = useHistory()
    const toLine = (lineCode: any) => {
        history.push({
            pathname: '/line',
            search: '?lineCode=' + lineCode
        })
    }

    if (loading) return content
    if (error) {
        content = <Error />
        return content
    }
    let lines = [
        { totalCount: data[0], todayCount: data[1] },
        { totalCount: data[2], todayCount: data[3] },
        { totalCount: data[4], todayCount: data[5] },

    ]
    // lines[0].power = data[0] / 1000
    // lines[0].count = data[1]
    // lines[0].e = data[2]
    if (!data[6]) {
        content = <LineLayout justify='start' padding={[25, 50, 0, 50]} gap={30} >

            {
                lines.map((item, idx) => {
                    return <div className='entry-item' onClick={() => { toLine(idx + 1) }}>
                        <div className='entry-item-title'>S{idx + 1}</div>
                        <LineLayout direction='column' gap={20} margin={[20, 0, 0, 10]}>
                            <DigitalFlop title="累计产量" decimals={0} count={item.totalCount} type='count' suffix='双' size={0.7} />
                            <DigitalFlop title="今日产量" decimals={0} count={item.todayCount} type='count' suffix='双' size={0.7} />
                        </LineLayout>
                        {/* <LineLayout justify='start' padding={[5, 0, 0, 15]}>
                            <Dashboard id={`w${idx}`} height={130} data={item.power / 200 * 100} />
                            <div style={{ marginTop: '30px', marginLeft: '10px', }}>
                                <DigitalFlop count={item.power} title='功率' type='count' size={0.7} suffix='kW' />
                            </div>
                        </LineLayout> */}
                        {/* <div className='line-info'><span>{_.round(item.count / 10000, 1)} 万双  |  {item.e} 度</span></div> */}

                        {/* <div className='line-info'><span>累积产量： {_.round(item.count / 10000, 1)}万双</span></div>
                    <div className='line-info'><span>累积能耗：{item.e}度</span></div> */}

                        <div className='jump-btn'>跳转</div>
                    </div>
                })
            }
            <div style={{ width: '350px', height: '250px', marginTop: 30 }}>
                <PieChart id='workshop-today-production' data={[{ name: "S1", value: data[1] }, { name: 'S2', value: data[3] }, { name: "S3", value: data[5] }]} type='percent' color={['#008080  ', '#d13a49', '#FFB100']} title="今日产量占比"    ></PieChart>
            </div>
            <div style={{ width: '350px', height: '250px', marginTop: 30 }}>
                <PieChart id='workshop-total-production' data={[{ name: "S1", value: data[0] }, { name: 'S2', value: data[2] }, { name: "S3", value: data[4] }]}type='percent' title="累积产量占比" color={['#008080', '#d13a49', '#FFB100']}  ></PieChart>
            </div>
        </LineLayout >
        return content
    }
    content = <LineLayout justify='between' padding={[25, 50, 0, 50]} >
        {
            lines.map((item, idx) => {
                return <div className='entry-item' onClick={() => { toLine(idx + 1) }}>
                    <div className='entry-item-title'>#{idx + 1}</div>
                    {/* <Dashboard id={`w${idx}`} height={130} data={item.power / 200 * 100} /> */}
                    {/* <div className='line-power'><span style={{ color: '#05d380' }}>{_.round(item.power)}</span>kw</div> */}
                    {/* <div className='line-info'><span>{_.round(item.count / 10000, 1)}万双 | {item.e}度</span></div> */}
                    <div className='jump-btn'>跳转</div>
                </div>
            })
        }
    </LineLayout >
    return content
}
