import React from 'react'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineLayout } from '@/layout'
import { LineChart } from '@/components/chart/lineChart'
import * as API from '@/api/request'
import moment from 'moment'
import _, { now } from 'lodash'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
// data 0累计产量 1今日产量  2 15天产量

const lineGroupCode = searchParams().lineGroupCode
interface Props {
    data?: Array<any>
    jumpTo?: string
}


const handleChartData = (data: Array<any>) => {
    let result = { xAxis: [], yAxis: [] }
    if (!Array.isArray(data)) return result
    if (data.length === 0) return result
    result.yAxis = data.map((item: any) => item.count)
    result.xAxis = data.map((item: any) => moment(item.timeRange.beginTime).format('HH:mm'))
    return result
}

interface CapacityProps {
  lineCode?: string
}
const Capacity = (props: CapacityProps) => {

  // let content = <Loading />

  let content = React.useRef(<Loading></Loading>)

  const lineCode = props.lineCode ? props.lineCode : searchParams().lineCode
  //获取实时产能
  let { data, loading, error } = useRequest(
    () => {
      return Promise.all(
        [
          API.Production.getCapacity(null,lineGroupCode),
          API.Production.getCapacityByTimeRange(null,lineGroupCode)
        ]
      )
    },
    { pollingInterval: 3 * 1000 }
  )


  if (loading) return content.current
  if (error) {
    content.current = <Error />
    return content.current
  }

  let capacityCount: any = data[0]
  let capacityChart: any = data[1]
  content.current = <React.Fragment>
    <LineLayout justify="start" padding={[20, 0, 0, 30]} gap={30}>
      <DigitalFlop
        type='count'
        title="最近一小时"
        count={capacityCount.lastHourCount}
        suffix={"双/小时"}
        size={1.6}
        minWidth={250}
      />
      <DigitalFlop size={1.6} title="今日单时最高" decimals={0} count={capacityCount.maxAmountOfOneHour.count} type='count' suffix={"双/小时"} />
      <div className='max-time' style={{ height: '30px', width: '150px', background: '#ffffff6e', color: "#fff", position: 'absolute', top: '30px', left: '700px', fontSize: "26px" }}>
        {capacityCount.maxAmountOfOneHour.beginTime + '-' + capacityCount.maxAmountOfOneHour.endTime}
      </div>
    </LineLayout>
    <LineChart margin="30px 0 0 0" id={'capacity-thumbnail_' + lineCode} height={250} title="产能趋势（双/小时）" titleSize={18} data={handleChartData(capacityChart)} axisStyle={{ fontSize: 15 }} />
  </React.Fragment>
  return content.current
}


export { Capacity }

