import { EasyGridLayout, LineLayout } from '@/layout'
import React, { useState } from 'react'
import { useRequest } from 'ahooks'
import { Equipment } from '@/api/request'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import _ from 'lodash'
import { getTimeDiff } from '@/utils/time'
import moment from 'moment'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import './index.scss'
import { useHistory } from 'react-router'
import { store } from '@/store'
import classNames from 'classnames'
interface Props {
  data?: Object | Array<any>
}

let content = <Loading />
const EquipmentStatus = (props: Props) => {
  content =<div className='equipment-status'>
    <LineLayout height={280} justify='between' padding={[20, 80, 0, 80]} >
        <div className='equipment-status-bg'>
          
                <div className='point'  style={{marginLeft:50}}></div>
                <div className='point' style={{marginLeft:210}}></div>
                <div className='point'  style={{marginLeft:70}}></div>
                <div className='point' style={{marginLeft:330}}></div>
                <div className='point' style={{marginLeft:205}}></div>
                <div className='point' style={{marginLeft:80}}></div>
                <div className='point' style={{marginLeft:70}}></div>


          

        </div>
    </LineLayout>
    </div>
  return content
}
export { EquipmentStatus }
