import React, { useEffect } from "react"
const echarts = require("echarts");

interface LineChartProps {
  id: string,
  title?: string,
  dataX: Array<any>,
  dataY: Array<any>
}

const LineChart = (props: LineChartProps) => {
  const addMaxLabel = (data) => {
    let maxIdx = 0
    let maxVal = Number.MIN_SAFE_INTEGER
    for (const key in data) {
      if (data[key] > maxVal) {
        maxVal = data[key]
        maxIdx = Number(key)
      }
    }
    data[maxIdx] = { value: data[maxIdx], label: { show: true, color: '#fff', position: 'inside', fontSize: 20 } }
    return data
  }

  const option = {
    title: {
      text: props.title,
      textStyle: {
        color: '#fff',
        fontWeight: 'normal',
        fontSize: 16
      },
      // 吐槽一下echarts的鬼操作⬇
      left: 'center'
    },
    grid: {
      top: 35,
      left: 45,
      right: 20,
      bottom: 20,
      width: 'auto',
      height: 'auto'
    },
    xAxis: {
      type: 'category',
      data: props.dataX,
      axisLine: {
        lineStyle: {
          color: '#fff'
        }
      },
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)'
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#fff'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)'
        }
      },
      min: (value) => {
        let num = 10 ** (value.min.toString().length - 2)
        return Math.floor(value.min / num) * num;
      },
      max: (value) => {
        let num = 10 ** (value.max.toString().length - 2)
        return Math.ceil(value.max / num) * num;
      }
    },
    series: [
      {
        data: addMaxLabel(props.dataY),
        type: 'line',
        smooth: true,
        itemStyle: {
          normal: {
            color: '#fff',
            fontSize: 14,
            lineStyle: {
              width: 3, // 折线宽度
              // 折线颜色渐变
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: 'rgba(45,105,249,1)'
              },
              {
                offset: 1,
                color: 'rgba(89,205,253,1)'
              }])
            }
          }
        }
      }
    ]
  }

  useEffect(() => {
    const MyChart = echarts.init(document.getElementById(props.id)); // 绘制图表
    MyChart.setOption(option)
  }, [props])

  return (
    <div>
      <div id={props.id} style={{ width: '625px', height: '155px', background: '#00000040' }}></div>
    </div>
  )
}

export { LineChart }