import React, { useState } from 'react'
import './index.scss'

interface DigitalBlockProps {

}


export const DigitalBlock = (props: DigitalBlockProps) => {
    return <div className='digital-block-content'></div>
}



