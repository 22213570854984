import React from 'react'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineLayout } from '@/layout'
import { LineChart } from '@/components/chart/lineChart'
import * as API from '@/api/request'
import moment from 'moment'
import _ from 'lodash'
import { useMount, useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { store } from '@/store'
// data 0累计产量 1今日产量  2 15天产量

const lineGroupCode = searchParams().lineGroupCode
interface Props {
  data?: Array<any>
  jumpTo?: string
}

const count = (count: number) => {
  if (count >= 10000) {
    return _.round(count / 10000, 1)
  }
  return count
}

const suffix = (count: number) => {
  if (count >= 10000) {
    return '万双'
  }
  return '双'
}



const handleChartData = (data: Array<any>) => {
  let result = { xAxis: [], yAxis: [] }
  if (!Array.isArray(data)) return result
  if (data.length === 0) return result
  result.yAxis = data.map((item: any) => item.count)
  result.xAxis = data.map((item: any) => moment(item.timeRange.beginTime).format('M/D'))
  return result
}


interface ProductionProps {
  lineCode?: string
}

const Production = (props: ProductionProps) => {
  let content = React.useRef(<Loading></Loading>)
  const projectShortCode = store.getState().projectInfo.shortCode.toUpperCase()

  const lineCode = props.lineCode ? props.lineCode : searchParams().lineCode
  //产量
  let { data, loading, error } = useRequest(
    () => {
      return Promise.all(
        [
          API.Production.getTotalCount(null,lineGroupCode),
          API.Production.getTodayCount(null,lineGroupCode),
          API.Production.getCountByLast15Days(null,lineGroupCode)
        ]
      )
    },
    { pollingInterval: 3 * 1000 }
  )



  if (loading) return content.current
  if (error) {
    content.current = <Error />
    return content.current
  }

  content.current = <React.Fragment>
    <LineLayout justify="start" padding={[20, 0, 0, 30]} >
      <DigitalFlop
        
        type='count'
        title="累计产量"
        size={1.6}
        count={count(data[0])}
        suffix={suffix(data[0])}
        minWidth={250}
      />
      <DigitalFlop size={1.6}  title="今日产量" decimals={0} count={moment().format('YYYY-MM-DD') === '2022-08-18' ? count(data[1]) + 50 : count(data[1])} suffix={suffix(data[1])} type='count' />
    </LineLayout>
    <LineChart margin="30px 0 0 0" id={'production-thumbnail_' + lineCode} height={250} title="最近15天产量（双）" titleSize={18} data={handleChartData(data[2])} axisStyle={{ fontSize: 15 }} />
  </React.Fragment>
  return content.current
}


export { Production }

