
import { store } from '@/store'
import React from 'react'
import QRCode from 'qrcode.react';
import './index.scss'

export const Qrcode = () => {
    const projectShortCode = store.getState().projectInfo.shortCode.toUpperCase()
    return <div className='qrcode'>
        {/* <div className='qrcode-item '>
            <QRCode
                value={'http://zs-tek.com/'}
                size={150}
                fgColor="#000000"
            />
        </div>
        <p>ZS-TEK.com</p> */}
     {   
        projectShortCode!=="T1"? <div className='qrcode-item ' style={{ marginTop: '40px' }}>
            <QRCode
                value={`http://m.${store.getState().projectInfo.shortCode}.zs-tek.com`}  //value参数为生成二维码的链接
                size={100}
                fgColor="#000000"
            />
            <p>移动端</p>
        </div>:null
     }   
    </div>
}
