import React, { useState } from 'react'
import { LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { PieChart } from '@/components/chart/pieChart'
import * as API from '@/api/request'
import _ from 'lodash'
import { useMount, useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { timeRanges } from '@/utils/time'

interface Props {
  data?: Array<any>,
  lineCode?: string
}



const WorkshopEquipment = (props: Props) => {

  // 设备


  const [loading, setLoading] = useState(true)
  const [utilizationRate, setUtilizationRate] = useState(0)
  const [energy, setEnergy] = useState(0)
  const [chartData, setChartData] = useState([])
  const handleChartData = (data: any) => {
    return [
      { name: '稼动时长', value: _.round(data.usedHours, 2) },
      { name: '待机时长', value: _.round(data.idleHours, 2) },
    ]
  }



  const getComponetData = () => {
    Promise.all([
      API.Equipment.getTodayUtilizationRate(props.lineCode),
      API.Equipment.getTodayElectricityValues(props.lineCode, { ...timeRanges.today() }),
      API.Equipment.getUtilizationHours(props.lineCode, { ...timeRanges.today() }),
    ]).then(res => {
      setUtilizationRate(res[0])
      setEnergy(res[1].quantityInKWH)
      setChartData(res[2])
      setLoading(false)
    })
  }

  useMount(() => {
    getComponetData()
    setInterval(() => getComponetData(), 3000)
  })







  let content = <React.Fragment>
    {loading ?
      <Loading /> :
      <>
        <LineLayout justify="start" padding={[20, 20, 0, 30]}>
          <DigitalFlop count={_.round(utilizationRate * 100, 1)} suffix="%" title="稼动率" minWidth={180} size={0.8} type='count' />
          <DigitalFlop count={_.round(energy, 1)} suffix="度" title="能耗" color="#efad1f" size={0.8} type='count' />
        </LineLayout>
        <PieChart
          id={'equipment-thumbnail' + props.lineCode}
          type='time'
          height={"160px"}
          margin="40px 0 0 0"
          title="稼动时长"
          color={['#05d380', '#eee']}
          data={handleChartData(chartData)} />
      </>
    }
  </React.Fragment>

  return content
}
export { WorkshopEquipment }
