import React from "react"
import './customCard.scss'

interface CustomCardProps {
  children?: Object
  width?: number | string
  height?: number | string
  borderStyle?: string
  bgImageUrl?: any
  padding?: string
}

const CustomCard = (props: CustomCardProps) => {
  const cardStyle = {
    width: props.width ? props.width + 'px' : '100%',
    height: props.height ? props.height + 'px' : '100%',
    border: props.borderStyle ? props.borderStyle : '',
    backgroundImage: props.bgImageUrl ? `url(${props.bgImageUrl})` : null,
    padding: props.padding ? props.padding : ''
  }
  return (
    <div className="customCard-container" style={cardStyle}>
      {props.children}
    </div>
  )
}

export { CustomCard }