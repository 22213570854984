import React from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { Border1 } from '@/components/border'
import { useRequest } from 'ahooks'
import { Call, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { CallLight } from '@/components/callLight'
import md5 from 'crypto-js/md5';
import './index.scss'
import { store } from '@/store'
interface Props { }
/**当前的产线编号 */
const allLineCodes = ['1', '2', '3', '4', '5', '6', '7', '8']
/** 拼接参数 获取跳转的url */
const getJumpTo = (address: string): string => `${address}?lineCode=${searchParams().lineCode}`
const Home = () => {
  const lineCode = searchParams().lineCode
  const { loading, data, error } = useRequest(() => {
    return Promise.all([
      Call.hasCallButtonSystem(searchParams().lineCode),
      Quality.getHasVisionInspectionSystem(searchParams().lineCode),
    ])
  })
  if (!searchParams().lineCode || !allLineCodes.includes(searchParams().lineCode)) {
    return <Error value='错误的产线编号' />
  }
  if (loading) return <Loading />
  const hasCallButtonSystem = data[0]
  const ovenModuleWidth = hasCallButtonSystem ? 1355 : 1920
  const thumbnailsList = {
    'd1': ['生产', '实时产能', '能源', '环境'],
    'd2': ['生产', '实时产能', '能源', '环境'],
    'e1': ['生产', '质量', '能源', '环境'],
    'jdd3': ['生产', '实时产能', '能源', '环境'],
    's1': ['生产', '实时产能', '能源', '环境'],
    't1': ['生产', '实时产能', '能源', '环境'],
    'x': ['生产', '实时产能', '能源', '环境'],
  }
  //需要根据现场需求 决定是展示产能还是质量 暂时先这样 后期应该通过接口返回配置
  let thumbnails: Array<any> = [
    { title: '生产', jumpTo: getJumpTo('/line/production'), children: <Thumbnail.Production />, border: <Border1 />, width: 420, icon: require('../../../assets/images/production.png') },
    { title: '质量', jumpTo: getJumpTo('/line/quality'), children: data[1] ? <Thumbnail.Quality /> : <Thumbnail.QualityNoVision />, border: <Border1 />, width: 420, icon: require('../../../assets/images/quality.png') },
    { title: '实时产能', children: <Thumbnail.Capacity />, border: <Border1 />, width: 420, icon: require('../../../assets/images/quality.png') },
    { title: '能源', jumpTo: getJumpTo('/line/equipment'), children: <Thumbnail.Equipment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/equipment.png') },
    { title: '环境', jumpTo: getJumpTo('/line/environment'), children: <Thumbnail.Environment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/th.png') },
  ]
  const shortCode = store.getState().projectInfo.shortCode.toLowerCase()
  let pageName = store.getState().projectInfo.name.toUpperCase()
  if (shortCode === "s1") {
    pageName = `ZS-TEK S${lineCode} 智能产线 `
    if (lineCode === '2') {
      pageName = `ZS-TEK S2-1 智能产线 `
    }
    if (lineCode === '4') {
      pageName = `ZS-TEK S2-2 智能产线 `
    }

  }

  if (shortCode === "t1" ) {
    pageName = `ZS-TEK 安踏智能产线 `
    const data = {
      test: "test",
    }
    let appid = "pingtest";
    let appsecret = "pingtest";
    let timestamp = new Date().getTime();
    let reqid = new Date().getTime();
    let body = JSON.stringify(data);
    const base64Body = btoa(body);
    const waitSign = appid + "&" + appsecret + "&" + base64Body + "&" + timestamp;
    //console.log(CryptoJS.Crypto)
    let sign = md5(waitSign).toString()
      console.log(timestamp)
      console.log(sign) 
      console.log(base64Body)

    let myHeaders = new Headers();
    //@ts-ignore
    myHeaders.append("timestamp", timestamp);
    myHeaders.append("appid", appid);
    myHeaders.append("apisign", sign);
    //@ts-ignore
    myHeaders.append("reqid", reqid);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };

    //@ts-ignore
    fetch("https://mes-test.anta.com/api/ping", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }

  if (shortCode === "X") {
    pageName = `ZS-TEK 东才X${lineCode}智能产线 `
  }
  return (
    <Layout name={pageName}>
      {
        // hasCallButtonSystem ? <CallLight /> : null
      }
      {<LineLayout margin={20} height={480}>
        {thumbnails
          .map((item) => (
            thumbnailsList[shortCode].indexOf(item.title) > -1 ?
              <Card title={item.title} jumpTo={item.jumpTo} showMoreBtn={true} border={item.border} width={item.width} children={item.children} icon={item.icon} /> : <></>
          ))}
      </LineLayout>}
      {/* 警讯、烘箱状态 */}
      <LineLayout height={440} justify='between' margin={[40, 20, 20, 20]}>
        {/* {
          hasCallButtonSystem ?
            <Card title='呼叫'
              jumpTo={getJumpTo('/line/andon')}
              showMoreBtn={true} border={<Border1 />}
              width={420} children={<Thumbnail.Andon />}
              icon={require('../../../assets/images/call.png')} /> : null
        } */



          shortCode === "x" ? <Card title={"质量"}
            showMoreBtn={true}

            border={<Border1 />}
            width={420} children={<Thumbnail.Quality />}
            icon={require('../../../assets/images/quality.png')} /> : null
        }
        <Card
          title='设备'
          showMoreBtn={false}
          border={<Border1 />}
          width={ovenModuleWidth}
          children={shortCode === "x" ? <Thumbnail.EquipmentStatus /> : <Thumbnail.Oven />}
          icon={require('../../../assets/images/oven.png')} />

        <Qrcode />
      </LineLayout>
    </Layout>
  )
}
export { Home }
