import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as IOT from '../pages/iot';
import * as WorkShop from '../pages/workShop'
import * as Line from '../pages/productionLine'
import * as OrganicSystem from '../pages/organicSystem'
import { Home } from '../pages/home'
import { LineSwiper } from '../pages/other/lineSwiper'
import { SFactoryBigScreenLineProduction } from '../pages/sFactoryBigScreen'
import { BigdataX1 } from '@/pages/other/bigdataX1'
import { PrivateRoute } from '@/components/privateRoute';
import { useMount } from 'ahooks';
import * as API from '@/api/request'
import { searchParams } from '@/utils';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import {Line2in1} from '../pages/productionLine/line2in1'
import {LineGroup} from '../pages/productionLine/lineGroup'
import {LineGroup2in1} from '../pages/productionLine/lineGroup/lineGroup2in1'
import { setProjectInfo, setHasCallButtonSystem, setInstallLineCodes, setRobots, setCameras, setOvenOrRefrigerators, setGlueBuckets, setHasStopperWorkStations } from '@/store';
import versionConfig from '../version.json';
import moment from 'moment';
import { requestBaseUrl } from '@/api/initEnv';
//建制中项目名单
const constructingInfo = [];
let isConstructiong = false;
constructingInfo.forEach((item: any) => {
  if (requestBaseUrl.indexOf(item) > -1) {
    isConstructiong = true;
  }
})

//检查是否过期
const checkExpirationTime = (time: string) => {
  const expirationTim = new Date(time)
  return expirationTim.getTime() < (new Date()).getTime()
}

//检查缓存配置
const checkProjectConfig = (projectConfig: any, serverCompiledVersion: string) => {
  if (!projectConfig) return true
  if (!projectConfig.appBuildTime) return true
  if (!projectConfig.serverCompiledVersion) return true
  if (projectConfig.appBuildTime !== versionConfig.appBuildTime) return true
  if (projectConfig.serverCompiledVersion !== serverCompiledVersion) return true
  if (checkExpirationTime(projectConfig.expirationTime)) return true
  return false
}

const Routes = () => {
  const lineCode = useState(searchParams().lineCode)[0];
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  useMount(isConstructiong ? () => { } : () => {
    const setStore = (projectConfig: any) => {
      setInstallLineCodes(projectConfig.installLineCodes)
      setProjectInfo({
        name: projectConfig.projectInfo.name,
        shortCode: projectConfig.projectInfo.shortCode,
        id: projectConfig.projectInfo.id
      })
      setHasCallButtonSystem(projectConfig.hasCallButtonSystem)
      setCameras(projectConfig.cameras)
      setRobots(projectConfig.robots)
      setOvenOrRefrigerators(projectConfig.ovenOrRefrigerators)
      setGlueBuckets(projectConfig.glueBuckets)
      setHasStopperWorkStations(projectConfig.hasStopperWorkStations)
      setLoading(false)
    }

    //获取服务器版本号
    API.Config.getServerCompiledVersion().then(res => {
      const serverCompiledVersion = res
      const projectConfig = JSON.parse(localStorage.getItem('projectConfig'))
      if (checkProjectConfig(projectConfig, serverCompiledVersion)) {
        Promise.all([
          API.Config.getProjectInfo(),
          API.Call.hasCallButtonSystem(lineCode),
          API.Config.getRobots(lineCode),
          API.Config.getCameras(lineCode),
          API.Equipment.getInstalledLineCodes(lineCode),
          API.Config.getOvenOrRefrigerators(),
          API.Config.getGlueBuckets(lineCode),
          API.Config.getHasStopperWorkStations(lineCode)
        ]).then(res => {
          const [projectInfo, hasCallButtonSystem, robots, cameras, installLineCodes, ovenOrRefrigerators, glueBuckets, hasStopperWorkStations] = res
          const projectConfig = {
            projectInfo,
            hasCallButtonSystem,
            robots,
            cameras,
            installLineCodes,
            serverCompiledVersion,
            appBuildTime: versionConfig.appBuildTime,
            expirationTime: moment().add(24, 'hours').valueOf(),
            ovenOrRefrigerators,
            glueBuckets,
            hasStopperWorkStations
          }
          localStorage.setItem('projectConfig', JSON.stringify(projectConfig))
          setStore(projectConfig)

        }).catch(err => {
          setLoading(false)
          setError(true)
        })
      } else {
        setStore(projectConfig)
      }
    })
  })

  if (!isConstructiong && loading) return <Loading />
  if (!isConstructiong && error) return <Error value='无法获取项目配置' />

  const lineRoutes = [
    { path: '/line/workStation', component: () => <Line.WorkStation /> },
    { path: '/line/production', component: () => <Line.Production /> },
    { path: '/line/oven', component: () => <Line.Oven /> },
    { path: '/line/andon', component: () => <Line.Andon /> },
    { path: '/line/quality', component: () => <Line.Quality /> },
    { path: '/line/equipment', component: () => <Line.Equipment /> },
    { path: '/line/environment', component: () => <Line.Environment /> },
    { path: '/line/line2in1', component: () => <Line2in1 /> },
    { path: '/line/lineGroup', component: () => <LineGroup /> },
    { path: '/line/lineGroup2in1', component: () => <LineGroup2in1 /> },
    { path: '/line', component: () => <Line.Home /> },
  ]
  const workShopRoutes = [
    { path: '/workshop/quality', component: () => <WorkShop.Quality /> },
    { path: '/workshop/production', component: () => <WorkShop.Production /> },
    { path: '/workshop/oven', component: () => <WorkShop.Oven /> },
    { path: '/workshop/equipment', component: () => <WorkShop.Equipment /> },
    { path: '/workshop/andon', component: () => <WorkShop.Andon /> },
    { path: '/workshop/environment', component: () => <WorkShop.Environment /> },
    { path: '/workshop', component: () => <WorkShop.Home /> },
  ]

  const iotRoutes = [
    { path: '/iotUpgradeSecond', component: <IOT.IOT /> },
    { path: '/iotUpgrade', component: <IOT.IOTUpgrade /> },
    { path: '/iot', component: <IOT.IOTUpgradeSecond /> },
  ]

  const organicSystem = [
    { path: '/organic/plan', component: <OrganicSystem.ConstructionPlan /> },
    { path: '/organic', component: <OrganicSystem.ConstructionHome /> }
  ]

  const otherRutes = [
    { path: '/sFactoryBigScreen', component: () => <SFactoryBigScreenLineProduction/> },
 
    { path: '/sFactoryBigScreen/LineProduction', component: () => <SFactoryBigScreenLineProduction/> },
    { path: '/bigdataX1', component: <BigdataX1 /> },
    { path: '/lineSwiper', component: <LineSwiper /> },
    { path: '/', component: isConstructiong ? <OrganicSystem.ConstructionHome /> : <Home /> },
  ]


  const routes = [
    ...lineRoutes,
    ...workShopRoutes,
    ...iotRoutes,
    ...organicSystem,
    ...otherRutes
  ]
  return (
    <Router>
      <Switch>
        {
          routes.map((item, idx) => {
            return <PrivateRoute path={item.path} content={item.component} key={idx} />
          })
        }
      </Switch>
    </Router>
  )
}

export default Routes
